var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createOrderStatus"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tên Trạng Thái","label-for":"h-status-name","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":"StatusName","customMessages":( _obj = {}, _obj['required'] = 'Không được bỏ trống', _obj )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-status-name","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.status_name),callback:function ($$v) {_vm.status_name=$$v},expression:"status_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tùy chỉnh màu","label-for":"h-color","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Colour","customMessages":( _obj$1 = {}, _obj$1['required'] = 'Không được bỏ trống', _obj$1 )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('colour-picker',{attrs:{"value":_vm.colour,"state":errors.length > 0 ? false:null,"picker":"chrome"},model:{value:(_vm.colour),callback:function ($$v) {_vm.colour=$$v},expression:"colour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Vị trí","label-for":"location","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required","customMessages":( _obj$2 = {}, _obj$2['required'] = 'Không được bỏ trống', _obj$2 )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-minus-plus',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Level","label-for":"level","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Level","rules":"required","customMessages":( _obj$3 = {}, _obj$3['required'] = 'Không được bỏ trống', _obj$3 )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-minus-plus',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status","label-cols-md":"3"}},[_c('b-form-checkbox',{attrs:{"id":"status","switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Bật ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"","label-cols-md":"3"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Cập Nhật ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }