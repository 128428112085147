<template>
  <b-card>
    <validation-observer ref="createOrderStatus">
      <b-form>
        <b-row>
          <!--  Full name-->
          <b-col md="12">
            <b-form-group
              label="Tên Trạng Thái"
              label-for="h-status-name"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="StatusName"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  id="h-status-name"
                  v-model="status_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Tùy chỉnh màu"
              label-for="h-color"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Colour"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <colour-picker
                  v-model="colour"
                  :value="colour"
                  :state="errors.length > 0 ? false:null"
                  picker="chrome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Vị trí"
              label-for="location"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="Location"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <input-minus-plus
                  v-model="location"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Level"
              label-for="level"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="Level"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <input-minus-plus
                  v-model="level"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Trạng thái"
              label-for="status"
              label-cols-md="3"
            >
              <b-form-checkbox
                id="status"
                v-model="status"
                switch
              > Bật </b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-form-group
              label=""
              label-cols-md="3"
            >
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Cập Nhật
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import InputMinusPlus from '@/@core/components/form/InputMinusPlus.vue'
import ColourPicker from 'vue-colour-picker'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormCheckbox 
} from 'bootstrap-vue'

export default {
  props: ['id'],
  components: {
    InputMinusPlus,
    'colour-picker': ColourPicker,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
  },
  methods: {
    validationForm() {
      this.$refs.createOrderStatus.validate().then(success => {
        console.log('submit', success)
      })
    },
  },
  data() {
    return {
      status_name: '',
      colour: '#000000',
      level: 1,
      location: 1,
      status: true,
      SetDefault: false,
      required,
    }
  },
}
</script>

<style>
.color-input {
  width: 50%;
}
</style>
